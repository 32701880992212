import React from "react";
import { Formik, Form } from "formik";
import InputAddon from "app/components/Input/InputAddon";
import { User } from "app/components/Icon";
import Alert from "app/components/Alert";
import { P1 } from "app/components/Typography";
import { useGetUserProfile } from "hooks/User";
import { useUserUpdateName } from "../hooks/useUserUpdateName";
import ButtonWithLoader from "./ButtonWithLoader";
import { InputWrapper, Label, FlexLeft, SectionWrapper } from "./styles";

export function NameInfo(): JSX.Element {
  const { profileData, loading: profileLoading } = useGetUserProfile();
  const [isSuccess, setIsSuccess] = React.useState(false);
  const { isUpdating, error, success, updateName } = useUserUpdateName();

  const loading = isUpdating || profileLoading;

  const handleReset = () => {
    updateName({ name: "" }).catch(() => {});
    setIsSuccess(false);
  };

  const handleSubmit = async (values: { name: string }) => {
    try {
      await updateName(values);
      setIsSuccess(true);
    } catch {
      // Error is handled by the hook
    }
  };

  const initialName = profileData?.name || "";

  return (
    <SectionWrapper>
      <Formik
        enableReinitialize
        initialValues={{
          name: initialName,
        }}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, isValid, values }) => (
          <Form>
            <Label>Full Name</Label>
            <P1 color="monochrome.6" mb={3}>
              Your full name will be visible to others.
            </P1>
            <InputWrapper>
              <InputAddon
                icon={User}
                name="name"
                errors={errors}
                touched={touched}
              />
            </InputWrapper>
            {error && (
              <Alert variant="danger" closeAlert={handleReset}>
                {error.message}
              </Alert>
            )}
            {(isSuccess || success) && !error && (
              <Alert variant="success" closeAlert={handleReset}>
                {success?.message || "Successfully updated name."}
              </Alert>
            )}
            <FlexLeft>
              <ButtonWithLoader
                width="211px"
                height="32px"
                disabled={values.name === initialName || !isValid}
                loading={loading}
                type="submit"
              >
                Update Name
              </ButtonWithLoader>
            </FlexLeft>
          </Form>
        )}
      </Formik>
    </SectionWrapper>
  );
}
